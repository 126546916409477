/* eslint-disable no-prototype-builtins */
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";

import { stringify } from "qs";
import moment from "moment";

import { decodeJwt } from "../utils/helperFunction";

export const domainUrl = `${process.env.REACT_APP_DOMAIN_URL_API}`;
export const masterDomainUrl = `${process.env.REACT_APP_HOST_API_MASTER}`;
export const fileDomainUrl = `${process.env.REACT_APP_FILE_HOST_API}`;
export const domainUrlMaster = `${process.env.REACT_APP_HOST_API_MASTER_DATA}`;
export const domainUrlWfx = `${process.env.REACT_APP_HOST_API_WFX_DATA}`;

export type QueryObject = { [key: string]: string | number | boolean | null };

// Add the 401 response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      // await logout();
    } else {
      return Promise.reject(error);
    }
  }
);

export default class ApiClient {
  /**
   *
   * @param url
   * @param params
   */
  static async get(
    url: string,
    params: object,
    query?: undefined | { [key: string]: string | boolean | null } | string
  ): Promise<AxiosResponse> {
    let requestUrl = query ? `${url}?${stringify(query)}` : url;
    if (typeof query === "string") {
      requestUrl = `${url}${query}`;
    }

    const response = await axios.get(domainUrl + requestUrl, {
      params,
      headers: this.getHeaders(),
      data: {},
    });
    return response;
  }

  static async getNoHeader(
    url: string,
    params: object,
    query?: undefined | { [key: string]: string | boolean | null } | string
  ): Promise<AxiosResponse> {
    let requestUrl = query ? `${url}?${stringify(query)}` : url;
    if (typeof query === "string") {
      requestUrl = `${url}${query}`;
    }
    const response = await axios.get(domainUrl + requestUrl, {
      params,
      headers: {
        "Content-Type": "application/json",
      },
      data: {},
    });
    return response;
  }

  static getHeaders(contentType = "application/x-www-form-urlencoded") {
    return {
      "Content-Type": contentType,
      authorization: this.getToken(),
    };
  }

  private static getToken() {
    const timeNow = moment();
    const accessToken = sessionStorage.getItem("token");
    if (!accessToken) {
      return "";
    }

    // Check token is expired
    const expiredToken = decodeJwt(accessToken)?.exp;
    const isTokenExpired = moment.unix(expiredToken ?? 0).isBefore(timeNow);
    if (!isTokenExpired) {
      const bearerToken = `Bearer ${accessToken.replace(/"/g, '')}`;
      return bearerToken;
    } else {
      return "";
    }
  }

  private static convertToPostData(obj: any, form: any, namespace: any) {
    const fd = form || new URLSearchParams();
    let formKey;

    for (const property in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(property)) {
        if (namespace) {
          if (!Number.isNaN(Number(property))) {
            formKey = `${namespace}[${property}]`;
          } else {
            formKey = `${namespace}.${property}`;
          }
        } else {
          formKey = property;
        }

        if (obj[property] instanceof Date) {
          fd.append(formKey, obj[property].toISOString());
        } else if (
          typeof obj[property] === "object" &&
          !(obj[property] instanceof File) &&
          !(obj[property] instanceof Blob)
        ) {
          this.convertToPostData(obj[property], fd, formKey);
        } else {
          fd.append(formKey, obj[property]);
        }
      }
    }
    return fd;
  }

  static async post(
    url: string,
    query: QueryObject,
    params: any,
    appendUrl?: string
  ): Promise<AxiosResponse> {
    const requestUrl = `${url}?${stringify(query)}${appendUrl || ""}`;

    const config: AxiosRequestConfig = {
      headers: this.getHeaders(),
    };

    const param = this.convertToPostData(params, undefined, undefined);
    const response = await axios.post(domainUrl + requestUrl, param, config);
    return response;
  }

  static async getJsonData(
    url: string,
    params: object,
    query?: undefined | { [key: string]: string | boolean } | string
  ): Promise<AxiosResponse> {
    let requestUrl = query ? `${url}?${stringify(query)}` : url;
    if (typeof query === "string") {
      requestUrl = `${url}${query}`;
    }

    const response = await axios.get(domainUrl + requestUrl, {
      params,
      headers: {
        "Content-Type": "application/json",
        Authorization: this.getToken(),
      },
      data: {},
    });

    return response;
  }
  static async postJsonData(
    url: string,
    query: QueryObject,
    params: any,
    extraConfig?: Partial<AxiosRequestConfig>
  ): Promise<AxiosResponse> {
    const requestUrl = `${url}?${stringify(query)}`;

    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: this.getToken(), // Use getToken for authorization header
      },
      ...extraConfig,
    };

    const response = await axios.post(domainUrl + requestUrl, params, config);

    return response;
  }

  static async postJsonDataNoHeader(
    url: string,
    query: QueryObject,
    params: any,
    extraConfig?: Partial<AxiosRequestConfig>
  ): Promise<AxiosResponse> {
    const requestUrl = `${url}?${stringify(query)}`;

    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
      },
      ...extraConfig,
    };

    const response = await axios.post(domainUrl + requestUrl, params, config);
    return response;
  }

  static async putJsonData(
    url: string,
    query: QueryObject,
    params: any
  ): Promise<AxiosResponse> {
    const requestUrl = `${url}?${stringify(query)}`;

    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: this.getToken(), // Use getToken for authorization header
      },
    };

    const response = await axios.put(domainUrl + requestUrl, params, config);
    return response;
  }

  static async delete(url: string, params: any): Promise<AxiosResponse> {
    const requestUrl = `${url}?${stringify(params)}`;
    const config: AxiosRequestConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: this.getToken(), // Use getToken for authorization header
      },
      // validateStatus,
    };
    const response = await axios.delete(domainUrl + requestUrl, config);
    return response;
  }

  // static async postMutipartData(url: string, query: QueryObject, params: any): Promise<AxiosResponse> {
  //   const requestUrl = `${url}?${stringify(query)}`;
  //   const config: AxiosRequestConfig = {
  //     headers: this.getHeaders('multipart/form-data'),
  //   };
  //   const form = new FormData();
  //   const param = this.convertToPostData(params, form, undefined);

  //   const response = await axios.post(fileDomainUrl + requestUrl, param, config);

  //   return response;
  // }

  static async postMutipartDataWithConfig(
    url: string,
    query: QueryObject,
    params: any,
    config: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    const requestUrl = `${url}?${stringify(query)}`;
    const form = new FormData();
    const param = this.convertToPostData(params, form, undefined);

    const response = await axios.post(
      fileDomainUrl + requestUrl,
      param,
      config
    );

    return response;
  }

  static async deleteFile(url: string, params: any): Promise<AxiosResponse> {
    const requestUrl = `${url}?${stringify(params)}`;
    const config: AxiosRequestConfig = {
      headers: this.getHeaders("application/json"),
      // validateStatus,
    };
    const response = await axios.delete(fileDomainUrl + requestUrl, config);
    return response;
  }

  static async postSewingMutipartData(
    url: string,
    query: QueryObject,
    params: any
  ): Promise<AxiosResponse> {
    const requestUrl = `${url}?${stringify(query)}`;
    const config: AxiosRequestConfig = {
      headers: this.getHeaders("multipart/form-data"),
    };
    const form = new FormData();
    const param = this.convertToPostData(params, form, undefined);

    const response = await axios.post(domainUrl + requestUrl, param, config);

    return response;
  }

  // static async downloadExcelPost(
  //   url: string,
  //   query: undefined | { [key: string]: string | boolean } | string,
  //   params: object,
  //   fileName = 'excel_table',
  //   isFinished = true,
  // ): Promise<AxiosResponse> {
  //   let requestUrl = query ? `${url}?${stringify(query)}` : url;
  //   if (typeof query === 'string') {
  //     requestUrl = `${url}${query}`;
  //   }
  //   const config: AxiosRequestConfig = {
  //     headers: this.getHeaders('application/json'),
  //     responseType: 'blob',
  //   };

  //   const response = await axios.post(domainUrl + requestUrl, params, config);
  //   if (isFinished) {
  //     const blob = new Blob([response.data], {
  //       type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //     });
  //     saveAs(blob, fileName);
  //   }
  //   return response;
  // }

  // static async downloadExcelGet(
  //   url: string,
  //   params: object,
  //   query?: undefined | { [key: string]: string | boolean } | string,
  //   fileName?: string,
  // ) {
  //   let requestUrl = query ? `${url}?${stringify(query)}` : url;
  //   if (typeof query === 'string') {
  //     requestUrl = `${url}${query}`;
  //   }
  //   const response = await axios.get(domainUrl + requestUrl, {
  //     params,
  //     headers: this.getHeaders('application/xlsx'),
  //     responseType: 'blob',
  //     data: {},
  //   });

  //   const blob = new Blob([response.data], {
  //     type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //   });
  //   saveAs(blob, fileName);
  //   return response;
  // }

  // static async downloadExcelGetXlsm(
  //   url: string,
  //   params: object,
  //   query?: undefined | { [key: string]: string | boolean | number } | string,
  //   fileName?: string,
  // ) {
  //   let requestUrl = query ? `${url}?${stringify(query)}` : url;
  //   if (typeof query === 'string') {
  //     requestUrl = `${url}${query}`;
  //   }
  //   const response = await axios.get(domainUrl + requestUrl, {
  //     params,
  //     headers: this.getHeaders('application/vnd.ms-excel'),
  //     responseType: 'blob',
  //     data: {},
  //   });

  //   const blob = new Blob([response.data], {
  //     type: 'application/vnd.ms-excel',
  //   });
  //   saveAs(blob, fileName);
  //   return response;
  // }

  static async uploadFileExcelPostMutipartData(
    url: string,
    query: QueryObject,
    params: any,
    option?: any
  ): Promise<AxiosResponse> {
    const requestUrl = `${url}?${stringify(query)}`;
    const config: AxiosRequestConfig = {
      headers: this.getHeaders("multipart/form-data"),
      onUploadProgress: option?.onUploadProgress,
    };
    // const form = new FormData();
    // const param = this.convertToPostData(params, form, undefined);
    const response = await axios.post(domainUrl + requestUrl, params, config);

    return response;
  }
}
