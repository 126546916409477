import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { IAuth } from "../../../@types/Auth";
import { changePasswordThunk, loginThunk } from "./AuthThunk";

interface IAuthState {
  isAuthenticated: boolean;
  token: string | null;
  error: string | null;
  auth?: any;
}

const initialState: IAuthState = {
  isAuthenticated: false,
  auth: {},
  token: null,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state: IAuthState) => {
      state.isAuthenticated = false;
      state.token = null;
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("jwTokenExpires");
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginThunk.fulfilled, (state, action: PayloadAction<any>) => {
        state.isAuthenticated = true;
        state.auth = action.payload.data;
        state.token = action.payload.data.jwToken;
        state.error = null;
      })
      .addCase(loginThunk.rejected, (state, action: PayloadAction<any>) => {
        state.isAuthenticated = false;
        state.token = null;
        state.error = "Username or password is incorrect";
      })
      .addCase(
        changePasswordThunk.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isAuthenticated = true;
          state.auth = action.payload.data;
          state.token = action.payload.data.jwToken;
          state.error = null;
        }
      )
      .addCase(
        changePasswordThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.token = null;
          state.error = "Don't Change";
        }
      );
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
