import { ToastContainer } from 'react-toastify';
import './App.css';
import Routes from './routes/index';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from './redux/hooks/hooks';
import { checkTokenValidity, setToken } from './utils/checkTokenLocalStorage';
import CookiePolicy from './components/CookiePolicy';
import ScrollToTop from './components/ScrollToTop';

function App() {
  const token = sessionStorage.getItem('token') ?? '';
  const jwTokenExpires = sessionStorage.getItem('jwTokenExpires') ?? '';
  const [isAuthenticatedApp, setIsAuthenticatedApp] = useState(!!token);
  const [hasCookieConsent, setHasCookieConsent] = useState(
    localStorage.getItem('cookieConsent') === 'true'
  );

  useEffect(() => {
    const checkTokenStatus = () => {
      const isValid = checkTokenValidity();
      const expirationDate = new Date(jwTokenExpires);
      const currentDate = new Date();

      if (!isValid || expirationDate <= currentDate) {
        setIsAuthenticatedApp(false);
      } else {
        setToken(token);
      }
    };

    checkTokenStatus();
  }, [token, jwTokenExpires]);

  useEffect(() => {
    if (token) {
      setIsAuthenticatedApp(true);
    } else {
      setIsAuthenticatedApp(false);
    }
  }, [token]);

  // Lắng nghe sự thay đổi của cookieConsent
  useEffect(() => {
    const handleStorageChange = () => {
      setHasCookieConsent(localStorage.getItem('cookieConsent') === 'true');
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <div className="App">
      <ScrollToTop />
      <Routes setIsAuthenticated={setIsAuthenticatedApp} isAuthenticated={isAuthenticatedApp} />
      <ToastContainer />
      {!hasCookieConsent && <CookiePolicy />}
    </div>
  );
}

export default App;
