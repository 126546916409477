import { createAsyncThunk } from "@reduxjs/toolkit";
import ApiClient from "../../ApiClient";

export interface ILogin {
  personName: string;
  address: string;
  profileImage: string;
  description: string;
  isMarried: boolean;
  phoneNumber: string;
  roleList: string[];
}
const loginThunk = createAsyncThunk<
  any, // Thay bằng kiểu dữ liệu cụ thể nếu có
  {
    userName: string;
    password: string;
  }
>("auth/login", async (user, thunkAPI) => {
  try {
    const request = {
      userName: user.userName,
      password: user.password,
    };

    const response = await ApiClient.postJsonData(
      `/Account/authenticate`,
      {},
      request
    );

    // Kiểm tra nếu đăng nhập thất bại
    if (!response.data.succeeded) {
      return thunkAPI.rejectWithValue(
        response.data.message || "Invalid credentials"
      );
    }

    // Nếu thành công, xử lý token
    const token = {
      userName: user.userName,
      password: user.password,
      token: response.data.data.jwToken,
      jwTokenExpires: response.data.data.jwTokenExpires,
    };

    sessionStorage.setItem(
      "jwTokenExpires",
      JSON.stringify(token.jwTokenExpires)
    );
    sessionStorage.setItem("token", JSON.stringify(token.token));

    return token; // Trả về dữ liệu token
  } catch (error: any) {
    // Xử lý lỗi khác (lỗi mạng, server)
    return thunkAPI.rejectWithValue(
      error.response?.data?.errors || "Unexpected error occurred"
    );
  }
});

const changePasswordThunk = createAsyncThunk<
  any,
  {
    userName: string;
    currentPassword: string;
    newPassword: string;
  }
>("auth/changePassword", async (user, thunkAPI) => {
  try {
    const request = {
      userName: user.userName,
      currentPassword: user.currentPassword,
      newPassword: user.newPassword,
    };

    const response = await ApiClient.postJsonData(
      `/Account/change-password`,
      {},
      request
    );

    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.errors);
  }
});

export { loginThunk, changePasswordThunk };
