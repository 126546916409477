// Lưu token và thời gian hết hạn vào sessionStorage
export const setToken = (token:string) => {
    const expiresAt = new Date();
    expiresAt.setHours(expiresAt.getHours() + 1); // Ví dụ: hết hạn sau 1 giờ
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('tokenExpiresAt', expiresAt.getTime().toString());
  };
  
  // Kiểm tra token khi load lại trang
  export const checkTokenValidity = () => {
    const token = sessionStorage.getItem('token');
    const tokenExpiresAt = sessionStorage.getItem('tokenExpiresAt');
    if (token && tokenExpiresAt) {
      const expiresAt = parseInt(tokenExpiresAt, 10);
      if (new Date().getTime() < expiresAt) {
        return true; // Token còn hiệu lực
      }
    }
    return false; // Token hết hạn hoặc không tồn tại
  };
    