import { path } from '../constant/path';
import { Navigate, RouteObject } from 'react-router-dom';
import { lazy, useEffect, useState } from 'react';
import Loadable from '../components/Loading/Loadable';

// MAIN'
const MainLayout = Loadable(lazy(() => import('../layout/MainLayout')));
const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
const CategoryPage = Loadable(lazy(() => import('../pages/CategoryPage')));
const AdminPage = Loadable(lazy(() => import('../pages/AdminPage')));
const NotFoundPage = Loadable(lazy(() => import('../pages/NotFound')));
const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));
const DetailPage = Loadable(lazy(() => import('../pages/DetailPage')));
const EventPage = Loadable(lazy(() => import('../pages/EventPage')));
const IntroductionPage = Loadable(lazy(() => import('../components/Footer/SinglePage/Introduction')));
const DisclosurePage = Loadable(lazy(() => import('../components/Footer/SinglePage/Disclosure')));
const DisclaimerPage = Loadable(lazy(() => import('../components/Footer/SinglePage/Disclaimer')));
const CareersPage = Loadable(lazy(() => import('../components/Footer/SinglePage/Careers')));

const MainRoutesFunctional = ({ isAuthenticated, setIsAuthenticated }: any) => {
  const MainRoutes: any = [
    {
      path: path.ROOT_PATH,
      element: <HomePage  />,
    },
    {
      path: path.LOGIN_PATH,
      element: <LoginPage setIsAuthenticated={setIsAuthenticated} />,
    },
    {
      path: `${path.CATEGORY_PATH}/:title`,
      element: <CategoryPage type="categoryID" />,
    },
    {
      path: `${path.CATEGORY_PATH}/search/:title`,
      element: <CategoryPage type="search" />,
    },

    {
      path: `${path.CATEGORY_PATH}/popular`,
      element: <CategoryPage type="popular" />,
    },
    {
      path: `${path.CATEGORY_PATH}/hot`,
      element: <CategoryPage type="hot" />,
    },
    {
      path: path.CATEGORY_PATH,
      element: <CategoryPage type="category" />,
    },
    {
      path: path.EVENT_PATH,
      element: <EventPage />,
    },
    {
      path: `${path.CATEGORY_PATH}/${path.EVENT_PATH}/:id`,
      element: <CategoryPage type="event" />,
    },
    {
      path: `${path.CATEGORY_PATH}/${path.RETAILER_PATH}/:id`,
      element: <CategoryPage type="retailer" />,
    },
    {
      path: path.NOT_FOUND_PATH,
      element: <NotFoundPage />,
    },
    {
      path: path.DETAIL_PATH,
      element: <DetailPage />,
    },
    {
      path: `${path.DETAIL_PATH}/:id`,
      element: <DetailPage isAuthenticated={isAuthenticated} />,
    },
    {
      path: path.INTRODUCTION_PATH,
      element: <IntroductionPage />,
    },
    {
      path: path.DISCLOSURE_PATH,
      element: <DisclosurePage />,
    },
    {
      path: path.DISCLAIMER_PATH,
      element: <DisclaimerPage />,
    },
    {
      path: path.CAREERS_PATH,
      element: <CareersPage />,
    },
    {
      path: `/${path.ADMIN_PATH}/:name`,
      element: isAuthenticated ? (
        <AdminPage isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
      ) : (
        <Navigate to={`/${path.LOGIN_PATH}`} replace={true} />
      ),
    },
    {
      path: path.ADMIN_PATH,
      element: isAuthenticated ? (
        <AdminPage isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
      ) : (
        <Navigate to={`/${path.LOGIN_PATH}`} replace={true} />
      ),
    },
  ];

  return MainRoutes;
};

export default MainRoutesFunctional;
